import { breakpoints, breakpointNames } from './utils/breakpoints';

const VIDEO_FILE = '1_Headervideo-';
const VIDEO_FILE_LOCATION = '/assets/images/home/1';
const videoPlayerWrapper = document.querySelector('#hero-video-player-wrapper');
const videoPlayer = document.querySelector('#hero-video-player');

const videoSources = {
  [breakpointNames.SMALL]: `${VIDEO_FILE}640-360`,
  [breakpointNames.MEDIUM_MIN]: `${VIDEO_FILE}853-480`,
  [breakpointNames.MEDIUM_MAX]: `${VIDEO_FILE}1280-720`,
  [breakpointNames.LARGE]: `${VIDEO_FILE}1920-1080`
};

class VideoPlayer {
  constructor() {
    this.videoPlayerSources = videoPlayer.querySelectorAll('source');
    this.currentBreakpoint;

    this.initialize();
  }

  isSafariBrowser() {
    return (
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') <= -1
    );
  }

  updateVideoElement(currentBreakpoint) {
    const videoSource = videoSources[currentBreakpoint];

    this.videoPlayerSources.forEach(source => {
      if (source.type === 'video/webm') {
        source.src = `${VIDEO_FILE_LOCATION}/${videoSource}.webm`;
      }

      if (source.type === 'video/mp4') {
        source.src = `${VIDEO_FILE_LOCATION}/${videoSource}.mp4`;
      }
    });

    videoPlayerWrapper.style.backgroundImage = `url(${VIDEO_FILE_LOCATION}/${videoSource}.jpg)`;

    videoPlayer.load();

    // Due to a open bug on Safari with object-fit cover not being aplied initially to some elements
    // we need to delay the presentation of the video until the size calculations are done by Safari.
    // Happens both on mobile and desktop in mobile viewports.
    // https://developer.apple.com/forums/thread/707247
    // https://developer.apple.com/forums/thread/709099
    if (!this.isSafariBrowser()) {
      videoPlayer.style.opacity = 1;
      videoPlayer.play();
    } else {
      videoPlayer.addEventListener('loadeddata', () => {
        setTimeout(() => {
          videoPlayer.style.opacity = 1;
          videoPlayer.play();
        }, 500);
      });
    }
  }

  breakpointResolver(pageWidth) {
    if (pageWidth <= breakpoints.small) {
      return breakpointNames.SMALL;
    }

    if (pageWidth <= breakpoints.mediumMin) {
      return breakpointNames.MEDIUM_MIN;
    }

    if (pageWidth <= breakpoints.mediumMax) {
      return breakpointNames.MEDIUM_MAX;
    }

    return breakpointNames.LARGE;
  }

  onWindowResize() {
    const pageWidth = window.innerWidth;
    const newBreakpoint = this.breakpointResolver(pageWidth);

    if (newBreakpoint !== this.currentBreakpoint) {
      this.currentBreakpoint = newBreakpoint;
      this.updateVideoElement(newBreakpoint);
    }
  }

  initialize() {
    window.addEventListener('resize', () => this.onWindowResize());

    this.onWindowResize();
  }
}

if (videoPlayerWrapper && videoPlayer) {
  new VideoPlayer();
}
