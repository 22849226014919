const studioFactsWrapper = document.querySelector('#studio-facts');

class StudioFacts {
  constructor(element) {
    this.activeIndex = 0;
    this.contentElements = element.querySelectorAll(
      '.col-studio-facts-content'
    );
    this.imageElements = element.querySelectorAll('.col-studio-facts-image');
    this.initialize();
  }

  clickHandler(element, index) {
    if (this.activeIndex !== index) {
      this.contentElements[this.activeIndex].classList.remove(
        'col-studio-facts-content--is-active'
      );

      this.contentElements[index].classList.add(
        'col-studio-facts-content--is-active'
      );

      this.imageElements[this.activeIndex].classList.remove(
        'col-studio-facts-image--is-active',
        'animate-down',
        'animate-up'
      );

      if (this.activeIndex > index) {
        // Slide down
        this.imageElements[index].classList.add(
          'col-studio-facts-image--is-active',
          'animate-down'
        );
      } else {
        // Slide up
        this.imageElements[index].classList.add(
          'col-studio-facts-image--is-active',
          'animate-up'
        );
      }

      this.activeIndex = index;
    }
  }

  initialize() {
    this.contentElements.forEach((element, index) => {
      element.addEventListener('click', () =>
        this.clickHandler(element, index)
      );
    });
  }
}

if (studioFactsWrapper) {
  new StudioFacts(studioFactsWrapper);
}
