export const breakpoints = {
  small: 480,
  mediumMin: 768,
  mediumMax: 1024,
  large: 1280,
  extraLarge: 1680,
  ultraLarge: 1920
};

export const breakpointNames = {
  SMALL: 'small',
  MEDIUM_MIN: 'mediumMin',
  MEDIUM_MAX: 'mediumMax',
  LARGE: 'large',
  EXTRA_LARGE: 'extraLarge',
  ULTRA_LARGE: 'ultraLarge'
};
